import React from "react"
import {graphql} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import Breadcrumbs from "../components/Common/Breadcrumbs"
import HubspotForm from "react-hubspot-form";
import ContactImage from "../assets/images/contact_image.png"

const CareerDetails = ({ data }) => {
  const parent = [{
    name: "Careers",
    link: 'careers'
  }];
  const { job_title, description, jd_image, Openings } = data.service
  const imageData = getImage(jd_image);
  return (
    <Layout>
      <Seo title={job_title} />

      <Navbar />
      {/*<PageBanner pageTitle={title} />*/}
      <div className="pt-120-b-80">
        <div className="container">
          <div className="section-title">
            <h1>{job_title}</h1>
            <div className="bar"/>
          </div>
          <Breadcrumbs pageTitle={job_title} parent={parent} />
          <div className="details-content">
            {imageData && (
              <GatsbyImage image={imageData} alt={job_title} className="details-image" />
            )}
            {/*<img src={image?.childImageSharp.fluid.srcWebp} style={{ float: 'left', margin: '40px' }} alt={title} />*/}
            <ReactMarkdown allowDangerousHtml={true} source={description} />
            { Openings > 0 && <div className="row justify-content-center">
              <div className="col-lg-6 d-none d-lg-block">
                <img src={ContactImage} alt="about" className="w-full" />
              </div>
              <div className="col-lg-6 text-center my-5">
                {/*<h3 className="my-5">Drop us a Message</h3>*/}
                <HubspotForm
                  portalId='23285786'
                  formId='ac8731bf-dc55-4345-b7eb-e926be3bc01b'
                  // onSubmit={() => navigate(`/`)}
                  // onReady={(form) => console.log('Form ready!')}
                  loading={<div>Loading...</div>}
                />
              </div>
            </div> }
            <div style={{ clear: "both" }}/>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleCareerDetails($slug: String) {
    service: strapiCareers(slug: { eq: $slug }) {
      job_title
      description
      Openings
      jd_image {
            childImageSharp {
              gatsbyImageData
            }
          }
    }
  }
`;

export default CareerDetails
